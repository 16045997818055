@use '@angular/material' as mat;
@use '@ngx-terabase/ui/colors';

.map {
  width: 100%;
  height: 100%;

  .ol-custom-overviewmap,
  .ol-custom-overviewmap.ol-uncollapsible {
    bottom: auto;
    left: auto;
    right: 73px;
    top: 33px;
    background-color: mat.get-color-from-palette(colors.$neutral, 1400) !important;
  }

  .ol-custom-overviewmap .ol-overviewmap-map {
    border: 2px solid mat.get-color-from-palette(colors.$neutral, 800);
    width: 230px;
    height: 230px;
    box-sizing: content-box;
  }

  .ol-custom-overviewmap .ol-overviewmap-box {
    border: 2px solid mat.get-color-from-palette(colors.$primary-orange, 1000);
  }

  .ol-custom-overviewmap:not(.ol-collapsed) button {
    display: none;
  }
}

.map-tools {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 11;
}

.map-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
}

.map-drawer {
  position: absolute;
  right: 40px;
  top: 0;
  background-color: mat.get-color-from-palette(colors.$neutral, 50);
  border-right: 1px solid mat.get-color-from-palette(colors.$neutral, 600);
  z-index: 10;
}

.darkBackground {
  background-color: mat.get-color-from-palette(colors.$neutral, 1400);
}

.pano-image {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 1000;
}

.hidden {
  visibility: hidden;
  z-index: -1;
}

.alignImageryCenter {
  margin-left: -460px !important;
}
