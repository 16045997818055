@use '@ngx-terabase/ui/colors';
@use 'sass:map';

.map {
  .drag-box {
    background-color: rgba(map.get(colors.$neutral, 50), 0.3);
    border: 1px solid map.get(colors.$neutral, 50);
  }

  .ol-control {
    button {
      color: transparent !important;
      &::before,
      &::after {
        content: '';
        position: absolute;
        background-color: transparent;
        width: 0;
        height: 0;
        clear: both;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid map.get(colors.$neutral, 50);
      }

      &::before {
        left: -14px !important;
        transform: rotate(90deg) !important;
      }

      &::after {
        left: 17px !important;
        transform: rotate(-90deg) !important;
      }
    }
  }
  .ol-swipe {
    background-color: transparent !important;
    &::before {
      width: 2px;
      transform: translate(-1px, 0);
    }
    &::after {
      background-color: transparent !important;
    }

    &:active {
      button::after,
      button::before {
        content: none !important;
      }
    }

    button {
      background-color: transparent !important;
      &:hover {
        outline: none !important;
      }
    }
  }
}
