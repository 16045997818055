@use '@angular/material' as mat;
@use '@ngx-terabase/ui/colors';

.mat-paginator-container {
  justify-content: space-between !important;

  .mat-option-text {
    font-size: 15px !important;
  }

  .mat-select-panel {
    &-wrap {
      .mat-select-panel {
        margin-top: -50px !important;
      }
    }
  }
}

.mat-paginator-page-size-label,
.mat-paginator-range-label {
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: mat.get-color-from-palette(colors.$neutral, 1000);
}

.mat-select-value-text {
  color: mat.get-color-from-palette(colors.$neutral, 1400);
  font-size: 15px;
}

.mat-form-field-underline {
  height: 0 !important;
}
