@forward 'theme/index';
@forward 'vendors/overrides/index';

@use 'sass:map';
@use '@ngx-terabase/ui/colors';
@use '@ngx-terabase/ui/typography';

input[type='time']::-webkit-calendar-picker-indicator {
  font-size: 16px;
}

.mat-mdc-card-content {
  padding: 0px !important;
}

tbs-date-picker {
  width: 100%;
}

mat-divider {
  border-top-color: #e4e4e4 !important;
}

.cdk-overlay-pane.mat-mdc-dialog-panel {
  --mat-dialog-container-max-width: 2000px;
}

.mat-drawer {
  --mat-sidenav-container-width: 222px;
}

.mat-mdc-header-row {
  --mat-table-header-container-height: 40px;
}

.mat-mdc-row {
  --mat-table-row-item-container-height: 25px;
}

.mat-mdc-cell {
  --mat-table-row-item-outline-width: 0px;
}

.mdc-dialog__content {
  @include typography.level(body-secondary);
  letter-spacing: unset !important;
}

.ag-theme-material {
  --ag-list-item-height: 42px !important;
  --ag-header-height: 48px !important;

  --ag-font-family: Arimo !important;

  .ag-header-cell {
    padding: 0;
    background-color: map.get(colors.$neutral, 200);
    color: map.get(colors.$neutral, 1400);
    @include typography.level(body-secondary, bold);
  }

  .ag-row {
    @include typography.level(body-secondary);
  }

  .ag-cell {
    padding: 0px 16px !important;
  }

  .ag-cell-wrapper {
    display: flex;
    align-items: center;
  }

  .ag-cell-label-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    overflow: hidden;
    padding-left: 16px;
  }

  .ag-layout-auto-height .ag-center-cols-clipper,
  .ag-theme-material .ag-layout-auto-height .ag-center-cols-container,
  .ag-theme-material .ag-layout-print .ag-center-cols-clipper,
  .ag-theme-material .ag-layout-print .ag-center-cols-container {
    min-height: 50px !important;
  }

  .ag-header-cell-comp-wrapper {
    .ag-grid-sort-icon {
      display: none;
      font-size: 16px;
    }
  }

  .ag-header-cell-comp-wrapper:hover {
    .ag-grid-sort-icon {
      display: initial;
      font-size: 16px;
      color: map.get(colors.$neutral, 1000);

      &:hover {
        color: map.get(colors.$neutral, 1400);
      }
    }
  }

  .ag-cell-inline-editing {
    border: 0px !important;
    height: unset !important;
  }

  .ag-row-hover:not(.ag-full-width-row),
  .ag-row-hover.ag-full-width-row.ag-row-group {
    .cell-editable-custom:not(.ag-cell-inline-editing) {
      cursor: pointer;
      &::before {
        content: '';
        margin: 0px 8px;
        border: solid 1px map.get(colors.$neutral, 600);
        display: block;
        position: absolute;
        top: 10px;
        left: 5px;
        right: 5px;
        bottom: 10px;
        pointer-events: none;
        border-radius: 2px;
        padding: 4px;
      }
    }
  }

  .ag-popup-child:not(.ag-tooltip-custom) {
    box-shadow: var(--ag-card-shadow);
  }
  .ag-floating-filter
    .ag-floating-filter-body
    .ag-floating-filter-input
    .ag-text-field
    .ag-text-field-input-wrapper
    .ag-text-field-input {
    border: 1px solid map.get(colors.$neutral, 800);
    border-radius: 3px;
    background-color: map.get(colors.$neutral, 50);
    height: 24px;
    align-items: center;
  }

  .ag-floating-filter-button .ag-floating-filter-button-button .ag-icon-filter {
    background: transparent url('/assets/images/icons/common/filter.svg') center/contain no-repeat;
    color: transparent;
    height: 12px;
    width: 12px;
  }
  .ag-floating-filter {
    padding: 0px 16px;

    &-body {
      position: relative;
      display: flex;
      flex: 1 1 auto;
      height: 100%;
      align-items: center;
    }
  }

  .ag-theme-material input[class^='ag-']:not([type]),
  .ag-theme-material input[class^='ag-'][type='text'],
  .ag-theme-material input[class^='ag-'][type='number'],
  .ag-theme-material input[class^='ag-'][type='tel'],
  .ag-theme-material input[class^='ag-'][type='date'],
  .ag-theme-material input[class^='ag-'][type='datetime-local'],
  .ag-theme-material textarea[class^='ag-'] {
    padding-bottom: 0;
  }

  .ag-row-hover {
    background-color: map.get(colors.$primary-blue, 50);
    cursor: pointer;
  }

  .ag-filter-icon {
    display: none;
  }

  ng-component {
    width: 100%;
  }

  .cell-editable-invalid {
    border: 1px solid var(--tbs-semantic-red-1000) !important;
  }
}

.ag-drag-handle {
  i {
    font-size: 16px;
    font-weight: bold !important;
    color: var(--tbs-neutral-1200);
  }
}

.ag-full-width-height {
  width: 100%;
  height: 100%;
}

.default-cursor {
  cursor: auto;
}

.mat-select-panel-taller {
  max-height: 500px !important;
}

.ag-theme-material .ag-layout-auto-height .ag-center-cols-viewport,
.ag-theme-material .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-material .ag-layout-print .ag-center-cols-viewport,
.ag-theme-material .ag-layout-print .ag-center-cols-container {
  min-height: 60px !important;
}

.print-avoid-break {
  break-inside: avoid;
}
