@import 'lightgallery/scss/lightgallery';

@import 'lightgallery/scss/lg-zoom';
@import 'lightgallery/scss/lg-thumbnail';
@import 'lightgallery/scss/lg-fullscreen';

// Buttons toolbar is now all black
.lg-show-in .lg-toolbar {
  z-index: 1083;
}

// Subhtml (caption) text is now on top
.lg-outer.lg-components-open:is(.lg-zoomed) .lg-sub-html {
  opacity: 1 !important;
  transition: opacity 0.2s ease-out 0.15s !important;
  position: fixed;
  z-index: 1082;
  width: 100%;
  top: 5px;
  background-color: rgba(0, 0, 0, 0.5);
}

.lg-outer.lg-components-open:not(.lg-zoomed) .lg-sub-html {
  opacity: 1 !important;
  transition: opacity 0.2s ease-out 0.15s !important;
  position: fixed;
  z-index: 1082;
  width: 100%;
  top: 5px;
  background-color: rgba(0, 0, 0, 0.5);
}

// Thumbnails are now allways on the bottom
.lg-custom-thumbnails {
  > .lg-outer {
    // Disable animation when zooming in
    .lg-components {
      transform: translate3d(0, 0%, 0);
    }

    .lg-thumb-outer {
      // Set the position of the thumbnails
      top: auto;
      bottom: 0;
      left: 0;
      right: 0;
      position: fixed;
      height: 100px;
      max-width: none;
    }
  }
}
