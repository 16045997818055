@use '@angular/material' as mat;
@use '@ngx-terabase/ui/colors';

.core-radio-button {
  &.mat-radio {
    &-button {
      .mat-radio {
        &-checked {
          .mat-radio {
            &-inner-circle {
              transition: none;
              background: mat.get-color-from-palette(colors.$primary-blue, 1000) !important;
            }
          }
        }

        &-inner-circle {
          background: mat.get-color-from-palette(colors.$primary-blue, 1000) !important;
        }

        &-outer-circle {
          border-width: 1px;
          border-color: mat.get-color-from-palette(colors.$primary-blue, 1000) !important;
        }
      }

      .mat-radio-ripple {
        display: none;
      }
    }
  }
}
