@use '@angular/material' as mat;
@use '@ngx-terabase/ui/colors';

.mat-autocomplete-panel {
  &.mat-autocomplete-visible {
    visibility: visible;
    margin-top: 5px;
    box-shadow: none;
    border-radius: 0;
    border: 1px solid mat.get-color-from-palette(colors.$primary-blue, 800);

    .mat-option {
      height: 48px;
      padding: 0 8px;
      background: mat.get-color-from-palette(colors.$neutral, 50);

      &:hover {
        background: mat.get-color-from-palette(colors.$primary-blue, 50);
      }

      &-text {
        margin-left: 2px;
        color: mat.get-color-from-palette(colors.$neutral, 1400);
      }

      /*

        MULTISELECT

      */
      .mat-pseudo-checkbox {
        width: 18px;
        height: 18px;
        border: 1px solid mat.get-color-from-palette(colors.$primary-blue, 1000);
        border-radius: 3px;

        &-checked {
          &:after {
            top: 4px;
            left: 3px;
            width: 8px;
            opacity: 1;
            height: 4px;
            box-sizing: content-box;
            transform: rotate(-45deg);
            border-bottom: 2px solid mat.get-color-from-palette(colors.$primary-blue, 1000);
            border-left: 2px solid mat.get-color-from-palette(colors.$primary-blue, 1000);
          }
        }
      }
    }
  }
}
