@use 'sass:map';
@use '@ngx-terabase/ui/colors';

.logistics-output-table-data-slicer-container {
  padding: 12px 0px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 10px;

  .data-slicer-title {
    display: flex;
    column-gap: 10px;
    margin-right: 10px;
    font-size: 15px;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
    width: 100px;
    margin-right: 25px;
  }

  .input-container {
    display: flex;
    column-gap: 10px;
    margin-right: 10px;
    width: calc(100% - 120px);

    tbs-input,
    tbs-select,
    tbs-datepicker {
      padding-bottom: 0;
    }

    .search-shipment-wrapper,
    .search-pallet-id-wrapper,
    .delivered-select-wrapper {
      width: 15%;
      max-width: 240px;
    }

    .datepicker-container {
      width: 250px;
      input {
        caret-color: transparent;
      }
    }
    .mat-calendar-icon {
      font-size: 24px;
      color: map.get(colors.$neutral, 1200);
    }
  }

  .actions-container {
    display: flex;

    button {
      width: 124px;
    }

    .tbs-button {
      margin-right: 10px;
    }
  }
}

.logistic-output-table-note-container {
  padding: 12px 0px;
}

.logistic-output-table-breadcrumb-container {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 700;
  column-gap: 8px;
  margin-bottom: 10px;
  padding: 0 14px;

  span:nth-child(odd) {
    cursor: pointer;
  }

  span:first-child {
    text-transform: uppercase;
  }

  span:last-child {
    color: map.get(colors.$primary-blue, 1000);
  }
}

.logistic-output-table-container {
  padding: 12px 0px;
  .tb-flag {
    visibility: hidden;
  }

  .ag-row-hover {
    .tb-flag {
      font-size: 20px;
      visibility: visible;
      cursor: pointer;

      &:hover {
        color: map.get(colors.$semantic-red, 1000);
      }
    }
  }

  .icon-text-cell-container {
    .mat-icon {
      margin-top: 10px;
      color: map.get(colors.$semantic-red, 1000);
      cursor: pointer;
    }
  }

  .shipment-output-cell {
    display: flex;
    align-items: center;
  }

  .ag-header-container,
  .ag-floating-top-container,
  .ag-floating-bottom-container,
  .ag-sticky-top-container {
    background: map.get(colors.$neutral, 200);
  }

  .ag-header-cell-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .ag-cell .ag-cell-wrapper .ag-cell-value {
    text-align: center;
  }

  .ag-floating-filter-button .ag-floating-filter-button-button .ag-icon-filter {
    background: transparent url('/assets/images/icons/common/filter.svg') center/contain no-repeat;
    color: transparent;
    height: 12px;
    width: 12px;
  }

  .ag-ltr .ag-floating-filter-button {
    margin-left: calc(var(--ag-grid-size) * 2);
  }

  .ag-root-wrapper {
    border: solid 0px;
    border-color: var(--ag-border-color);
  }

  .ag-floating-filter
    .ag-floating-filter-body
    .ag-floating-filter-input
    .ag-text-field
    .ag-text-field-input-wrapper
    .ag-text-field-input {
    border: 1px solid map.get(colors.$neutral, 800);
    border-radius: 3px;
    background-color: map.get(colors.$neutral, 50);
    height: 24px;
  }

  .ag-floating-filter:first-child
    .ag-floating-filter-body
    .ag-floating-filter-input
    .ag-text-field
    .ag-text-field-input-wrapper
    .ag-text-field-input {
    display: none;
  }

  .shipment-actual-cell {
    display: flex;
    align-items: center;
  }

  .flag-cell {
    .icon-text-cell-container {
      height: 100%;
      display: flex;
      align-items: center;

      .tb-flag-f {
        font-size: 20px;
        visibility: visible;
        cursor: pointer;
        color: map.get(colors.$semantic-red, 1000);
      }
    }
  }

  .ag-row-hover {
    background-color: map.get(colors.$primary-blue, 100);
    cursor: pointer;
  }

  .ag-row-odd {
    background-color: map.get(colors.$neutral, 200);
  }

  .ag-cell-focus {
    border-color: map.get(colors.$primary-blue, 600);

    &:focus-within {
      border-color: map.get(colors.$primary-blue, 600) !important;
    }
  }

  .ag-root-wrapper-body {
    border: 1px solid;
    border-color: var(--ag-border-color);
    min-height: 600px;
  }

  .ag-theme-material .ag-layout-auto-height .ag-center-cols-clipper,
  .ag-theme-material .ag-layout-auto-height .ag-center-cols-container,
  .ag-theme-material .ag-layout-print .ag-center-cols-clipper,
  .ag-theme-material .ag-layout-print .ag-center-cols-container {
    min-height: 600px;
  }

  .ag-theme-material .ag-status-bar {
    border: 0;
  }

  .ag-theme-material input[class^='ag-']:not([type]),
  .ag-theme-material input[class^='ag-'][type='text'],
  .ag-theme-material input[class^='ag-'][type='number'],
  .ag-theme-material input[class^='ag-'][type='tel'],
  .ag-theme-material input[class^='ag-'][type='date'],
  .ag-theme-material input[class^='ag-'][type='datetime-local'],
  .ag-theme-material textarea[class^='ag-'] {
    padding-bottom: 0;
  }
}
