@use 'src/theme/mixins';

// basic
.flex-column {
  @include mixins.flex(column, flex-start, flex-start);
}
.flex-row {
  @include mixins.flex(row, flex-start, flex-start);
}

// row
.flex-rsc {
  @include mixins.flex(row, flex-start, center);
}
.flex-rcs {
  @include mixins.flex(row, center, flex-start);
}
.flex-rcc {
  @include mixins.flex(row, center, center);
}
.flex-rbs {
  @include mixins.flex(row, space-between, flex-start);
}
.flex-rbc {
  @include mixins.flex(row, space-between, center);
}
.flex-rec {
  @include mixins.flex(row, flex-end, center);
}
.flex-res {
  @include mixins.flex(row, flex-end, flex-start);
}

// column
.flex-ccc {
  @include mixins.flex(column, center, center);
}
.flex-ccs {
  @include mixins.flex(column, center, flex-start);
}
.flex-csc {
  @include mixins.flex(column, flex-start, center);
}
.flex-cec {
  @include mixins.flex(column, flex-end, center);
}
.flex-cbs {
  @include mixins.flex(column, space-between, flex-start);
}
.flex-cbc {
  @include mixins.flex(column, space-between, center);
}
.flex-cbb {
  @include mixins.flex(column, space-between, space-between);
}

// gap
.flex-gap-5 {
  gap: 5px;
}
.flex-gap-10 {
  gap: 10px;
}
.flex-gap-20 {
  gap: 20px;
}
.flex-gap-30 {
  gap: 30px;
}

// reverse
.flex-rr {
  flex-direction: row-reverse;
}
.flex-cr {
  flex-direction: column-reverse;
}

.flex-grow-1 {
  flex-grow: 1;
}
