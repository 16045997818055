@use '@angular/material' as mat;
@use '@ngx-terabase/ui/colors';

@mixin flex-height-grow {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin flex($direction: inherit, $justify-content: inherit, $align-items: inherit) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify-content;
  align-items: $align-items;
}
