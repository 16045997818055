@use '@angular/material' as mat;
@use '@ngx-terabase/ui/colors';
@use '@ngx-terabase/ui/typography';

.switcher-labels {
  padding: 12px 6px 12px 12px;
  box-sizing: border-box;
  width: 230px;
  max-height: 700px;
  overflow-x: hidden;
  overflow-y: scroll;
  border: 2px solid mat.get-color-from-palette(colors.$primary-blue, 1200);
  border-radius: 0 0 2px 2px;

  &-header {
    width: 100%;
    margin-bottom: 5px;
  }

  h4 {
    @include typography.level(body-secondary, bold);
    color: mat.get-color-from-palette(colors.$neutral, 1400);
    margin: 2px 0 0 0;
  }

  &-item {
    display: flex;
    align-items: center;
    width: 100%;

    p {
      @include typography.level(caption);
      color: mat.get-color-from-palette(colors.$neutral, 1400);
      height: 30px;
      display: flex;
      align-items: center;

      .zoom-information {
        color: mat.get-color-from-palette(colors.$neutral, 800);
        margin-left: 5px;
      }
    }
  }
}
