@use '@angular/material' as mat;
@use '@ngx-terabase/ui/colors';

.sidenav-container .mat-drawer-inner-container {
  overflow: hidden !important;
}

.mat-sidenav {
  display: flex;
  flex-direction: column;
  --mat-sidenav-container-background-color: #{mat.get-color-from-palette(colors.$primary-blue, 1400)};
}
