@forward 'flex';
@forward 'defaults';

@forward 'components/map';
@forward 'components/switcher';
@forward 'components/pano-image-dialog';
@forward 'components/imagery_swiper';
@forward 'components/ag_grid_tables';
@forward 'components/lightgallery';

.version {
  position: fixed;
  bottom: 5px;
  left: 22px;
  z-index: 1000;
  color: darkgrey;
  font-size: x-small;
}
