@use '@ngx-terabase/ui/colors';
@use 'sass:map';

.pano-image-dialog {
  .mat-dialog-container,
  .mat-dialog-actions {
    padding: 0;
  }

  .psv-notification {
    bottom: 0;
  }
  .psv-compass {
    position: absolute;
    z-index: 100;
    top: 20px;
    left: 20px;

    svg {
      position: absolute;
    }
  }
}

.drone-imagery-date {
  background: map.get(colors.$primary-blue, 50) !important;
  font-weight: 700;
}
