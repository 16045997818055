@use 'sass:map';
@use '@ngx-terabase/ui/colors';

.data-slicer-popup {
  .mat-tab-nav-bar,
  .mat-tab-header {
    border-bottom: none;
  }

  .mat-tab-body-content {
    min-height: 150px;
  }

  .mat-tab-label {
    margin-bottom: 7px;
  }

  .mat-tab-label {
    min-width: 42px;
    max-width: 100px;
    padding: 3px 8px;
    border-radius: 2px;
    margin-right: 15px;
    height: 20px;
    color: map.get(colors.$neutral, 1000);
    background-color: map.get(colors.$neutral, 400);
  }

  .mat-tab-label-active {
    color: map.get(colors.$primary-blue, 1200);
    background: map.get(colors.$primary-blue, 200);
  }

  .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    display: none;
  }
}
