@use '@angular/material' as mat;
@use '@ngx-terabase/ui/colors';
@forward 'src/theme/mixins';
@forward 'material/autocomplete';
@forward 'material/sidenav';
@forward 'material/divider';
@forward 'material/radio';
@forward 'material/tab';
@forward 'material/input';
@forward 'material/paginator';

.cdk-overlay-dark-backdrop {
  background: mat.get-color-from-palette(colors.$neutral, 1400, 0.8);
}

a {
  color: mat.get-color-from-palette(colors.$primary-blue, 1000);
}

.pano-modal .mat-dialog-container {
  padding: 0;
}
