@use 'sass:map';
@use '@ngx-terabase/ui/colors';

.separator {
  width: 100%;
  height: 1px;
  display: block;
  background: map.get(colors.$primary-blue, 800);
}

.disabled {
  pointer-events: none !important;
}

.italic {
  font-style: italic;
}

.pointer {
  cursor: pointer;
}

/*
* Customized checkbox
*/

/* Customize the label (the container) */
.styled-checkbox-container {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's information checkbox */
.styled-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.styled-checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

//DEFAULT STYLED CHECKBOX

.styled-checkbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 3px;
  position: relative;
  vertical-align: middle;
  box-sizing: border-box;
  border: 1px solid map.get(colors.$primary-blue, 1000);
  transition:
    border-color 90ms cubic-bezier(0, 0, 0.2, 0.1),
    background-color 90ms cubic-bezier(0, 0, 0.2, 0);

  &.checked {
    &:after {
      top: 4px;
      left: 3px;
      width: 8px;
      opacity: 1;
      content: '';
      height: 4px;
      position: absolute;
      box-sizing: content-box;
      transform: rotate(-45deg);
      border-left: 2px solid #2b5390;
      border-bottom: 2px solid #2b5390;
      border-bottom: 2px solid currentColor;
      transition: opacity 90ms cubic-bezier(0, 0, 0.2, 0.1);
    }
  }
  input {
    opacity: 0;
  }
}

//TODO: MOVE TO COMPONENT

/*Checkbox as switch*/
.switch {
  width: 32px;
  height: 16px;
  position: relative;
  display: inline-block;
}

.switch input {
  display: none;
}

.slider {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s;
  cursor: pointer;
  position: absolute;
  background: map.get(colors.$neutral, 600);
}

.slider:before {
  left: 2px;
  bottom: 2px;
  width: 12px;
  content: '';
  height: 12px;
  transition: 0.4s;
  position: absolute;
  background-color: #fff;
}

input:checked + .slider {
  background: map.get(colors.$primary-green, 1000);
}

input:checked + .slider:before {
  transform: translateX(16px);
}

.slider.round {
  border-radius: 15px;
}

.slider.round:before {
  border-radius: 50%;
}

/**

MARKETPLACE - HEADER

*/

.marketplace {
  &-header {
    padding-top: 0;
    position: relative;
    margin-bottom: 32px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    top: 0;

    h3 {
      color: map.get(colors.$primary-blue, 1000);
      margin-bottom: 0;
    }
  }
}

.horizontal-line {
  width: 80%;
  background-color: map.get(colors.$neutral, 600);
  height: 1px;
  border: none;
}

// For input type number remove arrows
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

// Rounded elements with box shadow
.rounded-with-shadow {
  border: 1px solid map.get(colors.$neutral, 400);
  box-shadow: 0px 3px 5px rgba(69, 69, 69, 0.2);
  border-radius: 4px;
}

.container-with-side-bar-shrinked {
  width: calc(100% - 31px);
  left: 31px;
  transition: all 0.2s ease-in-out 0.2s;
}

.container-with-side-bar-opened {
  position: relative;
  width: calc(100% - 230px);
  left: 230px;
  transition: all 0.1s ease-in-out 0.1s;
}

.ci-check-circle-32-f {
  color: map.get(colors.$primary-green, 1000);
}

.tb-hourglass {
  color: map.get(colors.$neutral, 1000);
}

// Datepicker Tooltip
.datepicker-with-tooltip {
  tr > td:first-of-type > .datepicker-tooltip .datepicker-tooltip-text {
    left: 0;
    margin-left: 0;
  }

  tr > td:nth-of-type(7) > .datepicker-tooltip .datepicker-tooltip-text,
  tr:first-of-type > td:last-of-type > .datepicker-tooltip .datepicker-tooltip-text {
    right: 0;
    margin-left: 0;
    left: auto;
  }

  .datepicker-tooltip .datepicker-tooltip-text {
    visibility: hidden;
    background-color: map.get(colors.$primary-blue, 1400);
    color: map.get(colors.$neutral, 50);
    text-align: center;
    border-radius: 2px;
    padding: 8px 12px;
    position: absolute;
    z-index: 1;
    bottom: 120%;
    font-size: 13px;
    width: max-content;
  }

  .datepicker-tooltip:hover::after {
    content: '';
    position: absolute;
    top: -25%;
    left: 50%;
    margin-left: -8px;
    border-width: 8px;
    border-style: solid;
    border-color: map.get(colors.$primary-blue, 1400) transparent transparent transparent;
  }
  .datepicker-tooltip:hover .datepicker-tooltip-text {
    visibility: visible;
  }
}

.invalid-kpi {
  color: map.get(colors.$semantic-red, 1200) !important;
}

.autodetection-classes-count-img {
  margin-right: 10px;
  height: 16px;
  width: 16px;
}

.h-500 {
  height: 500px;
}

.loader-alignment {
  height: 72vh;
  padding: 18px;
}

.vertical-separator {
  width: 1px;
  height: 60px;
  background-color: map.get(colors.$neutral, 600);
}

.mat-select-panel.inspection-check-status .mat-option,
.inspection-status-trigger {
  .ci-x-circle-16-f {
    color: map.get(colors.$semantic-red, 1000);
  }

  .ci-check-circle-16-f {
    color: map.get(colors.$semantic-green, 1200);
  }
}
